import { BaseEntity } from './BaseEntity';

export enum CtaPosition {
  ZERO_NO_SHOW = '0',
  ONE_TOP_LEFT = '1',
  TWO_TOP_CENTER = '2',
  THREE_TOP_RIGHT = '3',
  FOUR_MIDDLE_LEFT = '4',
  FIVE_MIDDLE_CENTER = '5',
  SIX_MIDDLE_RIGHT = '6',
  SEVEN_BOTTOM_LEFT = '7',
  EIGHT_BOTTOM_CENTER = '8',
  NINE_BOTTOM_RIGHT = '9',
}

export enum AdvertisementPosition {
  HOME = 'home',
  HOME_GUEST = 'home_guest',
  ACCOUNT_SETTING = 'account_setting',
  VIDEO = 'video',
}

export interface Advertisement extends BaseEntity {
  name: string
  position: AdvertisementPosition
  image: string
  imageLink: string
  link: string
  mobileImage: string
  mobileImageLink: string
  show: number
  ctaPosition: CtaPosition
}

export type PartialAdvertisement = Partial<Advertisement>;
