
import {
  defineComponent,
  ref,
  unref,
  toRefs,
  onMounted
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  ElMessage,
  ElForm
} from 'element-plus';
import useFormErrors from '@/utils/validate';
import { ResponseError, getAdvertisement, IntBoolean } from '@/services/api';
import ImageUploader, { UploadImageEvent } from '@/components/ImageUploader.vue';
import { useUpdateAdvertisement } from '@/composables/api';
import {
  PartialAdvertisement,
  AdvertisementPosition,
  CtaPosition
} from '@/interfaces/Advertisements';
import { useI18n } from 'vue-i18n';


const DEFAULT_FORM_VALUES: PartialAdvertisement = {
  name: '',
  position: AdvertisementPosition.HOME,
  image: '',
  imageLink: '',
  mobileImage: '',
  mobileImageLink: '',
  link: '',
  ctaPosition: CtaPosition.ZERO_NO_SHOW
};

const CTAPOSITION_OPTIONS = Object
  .entries(CtaPosition)
  .map(([label, value]) => ({ label, value }));

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ImageUploader
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const page = useRoute().query.page;

    const advertisementsId = useRoute().params.id as string;
    const router = useRouter();
    const { formErrors, bindFormItemError } = useFormErrors();
    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateAdvertisement();
    const { t } = useI18n();


    const rules = {
      name: [
        {
          required: true,
          trigger: 'blur'
        }
      ],
      position: [
        {
          required: true,
          trigger: 'blur'
        }
      ],
      image: [
        {
          required: true,
          trigger: 'blur'
        }
      ],
      mobileImage: [
        {
          required: true,
          trigger: 'blur'
        }
      ],
      link: [
        {
          required: true,
          trigger: 'blur',
          type: 'url'
        }
      ]
    };

    onMounted(async() => {
      if (isEdit.value) {
        const res = await getAdvertisement({ advertisementsId });
        formValues.value = res.data;
      }
    });

    /**
     * 圖片上傳
     */
    const handleImageUpload = ({ path }: UploadImageEvent, from: string) => {
      formValues.value[from] = path;
    };

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          if (isEdit.value) {
            update({ advertisementsId, data: formValues.value }, {
              onSuccess() {
                ElMessage.success({
                  message: 'success!',
                  type: 'success'
                });

                router.push({
                  name: 'list-advertisements'
                });
              },
              onError(error: ResponseError) {
                ElMessage.error({
                  message: error.response?.data.message,
                  type: 'error'
                });
                formErrors.value = error.response?.data.errors;
              }
            });
          }
        }
      });
    };

    return {
      page,
      rules,
      formRef,
      formValues,
      formErrors,
      isUpdatedLoading,
      IntBoolean,
      submitForm,
      handleImageUpload,
      bindFormItemError,
      CTAPOSITION_OPTIONS,
      t
    };
  }
});
